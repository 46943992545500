import React from "react";
const Amsterdam = () => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      fill="white"
      viewBox="0 0 512 512"
    >
      <g fill="white">
        <g>
          <polygon
            points="273.655,0 273.655,35.31 297.195,35.31 297.195,88.276 361.931,88.276 361.931,52.966 397.241,52.966 
			397.241,88.276 461.977,88.276 461.977,35.31 485.517,35.31 485.517,0 		"
          />
        </g>
      </g>
      <g fill="white">
        <g>
          <path
            d="M26.483,435.494V512h88.276v-35.31c0-9.75,7.905-17.655,17.655-17.655c9.75,0,17.655,7.905,17.655,17.655V512h88.276
			v-76.506H26.483z"
          />
        </g>
      </g>
      <g fill="white">
        <g>
          <path
            d="M132.414,5.885L26.483,123.586v276.598h211.862V123.586L132.414,5.885z M114.759,370.759h-35.31v-47.08h35.31V370.759z
			 M114.759,288.368h-35.31v-47.08h35.31V288.368z M114.759,205.977h-35.31v-47.08h35.31V205.977z M132.414,129.471
			c-12.98,0-23.54-10.56-23.54-23.54s10.56-23.54,23.54-23.54c12.982,0,23.54,10.56,23.54,23.54
			C155.949,118.913,145.389,129.471,132.414,129.471z M185.379,370.759h-35.31v-47.08h35.31V370.759z M185.379,288.368h-35.31
			v-47.08h35.31V288.368z M185.379,205.977h-35.31v-47.08h35.31V205.977z"
          />
        </g>
      </g>
      <g fill="white">
        <g>
          <path
            d="M273.655,123.586v276.598h211.862V123.586H273.655z M361.931,370.759h-35.31v-47.08h35.31V370.759z M361.931,288.368
			h-35.31v-47.08h35.31V288.368z M361.931,205.977h-35.31v-47.08h35.31V205.977z M432.552,370.759h-35.31v-47.08h35.31V370.759z
			 M432.552,288.368h-35.31v-47.08h35.31V288.368z M432.552,205.977h-35.31v-47.08h35.31V205.977z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M273.655,435.494V512h52.966v-35.31c0-9.75,7.905-17.655,17.655-17.655s17.655,7.905,17.655,17.655V512h35.31v-35.31
			c0-9.75,7.905-17.655,17.655-17.655s17.655,7.905,17.655,17.655V512h52.966v-76.506H273.655z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};

export default Amsterdam;

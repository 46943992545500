import React, { useState } from "react";
import { GridLoader } from "react-spinners";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import TextInput from "../components/TextInput";
import Cycling from "../images/bicycle.svg";
import Bike from "../images/Bike";
import Run from "../images/Run";
import AmsterdamSvg from "../images/Amsterdag.svg";
import KathmanduSvg from "../images/kathmandu.svg";
import KathmantourImage from "../images/kathmantour.png";
import CountUp from "react-countup";
import Running from "../images/running.svg";
import useMedia from "use-media";
import { useApiData, useActions } from "react-api-data";
import {
  Link,
  FormattedMessage,
  useIntl,
} from "gatsby-plugin-intl";
import {
  RadioButton,
  RadioImg,
  PickLabel,
  RadioContainer,
} from "./DonationPage";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Amsterdam from "../images/Amsterdam";
import Kathmandu from "../images/Kathmandu";
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";

const AddTour = () => {
  const [title, setTitle] = useState("");
  const [name, setName] = useState("");
  const [kilometers, setKilometers] = useState(undefined);
  const [type, setType] = useState("cycling");
  const actions = useActions();
  const disabled =
    !title.length || !name.length || (kilometers && kilometers < 0);
  return (
    <AddTourContainer>
      <CardTitle>Add your activity</CardTitle>
      <LongTextInput
        onChange={(e) => {
          setTitle(e.target.value);
        }}
        text={"Give your activity a name"}
        value={title}
      />
      <InputContainer>
        <StyledTextInput
          onChange={(e) => {
            setName(e.target.value);
          }}
          text={"Your name"}
          value={name}
        />
        <StyledTextInput
          onChange={(e) => {
            setKilometers(e.target.value);
          }}
          type="number"
          text={"The distance in kilometers you did"}
          value={kilometers}
        />
      </InputContainer>
      <SubTitle>What activity did you do?</SubTitle>
      <IconContainer>
        <StyledRadioContainer>
          <RadioButton
            onClick={() => {
              setType("cycling");
            }}
            isSelected={type === "cycling"}
          >
            <RadioImg src={Cycling} />
          </RadioButton>
          <PickLabel>Cycling</PickLabel>
        </StyledRadioContainer>
        <StyledRadioContainer>
          <RadioButton
            onClick={() => {
              setType("running");
            }}
            isSelected={type === "running"}
          >
            <RadioImg src={Running} />
          </RadioButton>
          <PickLabel>Running</PickLabel>
        </StyledRadioContainer>
      </IconContainer>

      <ButtonConfirm
        disabled={disabled}
        onClick={() => {
          actions
            .perform("postTour", {}, { title, name, kilometers, type })
            .then(actions.invalidateCache("getTours"));
        }}
      >
        Add activity
      </ButtonConfirm>
    </AddTourContainer>
  );
};

const KathmanTourPage = (props) => {
  const intl = useIntl();
  const tours = useApiData("getTours");
  const isDesktop = useMedia({ minWidth: "480px" });
  const kilometersCovered =
    tours.data?.reduce((a, b) => a + b.kilometers, 0) ?? 0;
  const kilometersToCover = 8336;
  return (
    <Layout>
      <Helmet title={`Kathmantour | ${config.siteTitle}`} />
      <HeaderWrapper
        style={{
          backgroundImage: `url(${KathmantourImage})`,
        }}
      ></HeaderWrapper>
      <Container>
        <GridLoader
          sizeUnit={"px"}
          size={50}
          margin={"15px"}
          color={"#007ec9"}
          loading={tours.loading}
        />
        {tours.data && !tours.loading && (
          <>
    
    <DonateButton to="/donation?type=foundation#step2">
            Donate
          </DonateButton>
            <AddTour />
            <ProgressContainer>
              <DistancesContainer>
                <DistanceTile>
                  <CardTitle>Kilometers covered</CardTitle>
                  <Count style={{ color: "#007ec9" }}>
                    <CountUp duration={2.0} end={kilometersCovered} />
                  </Count>
                </DistanceTile>
                <LineItem />
                <DistanceTile>
                  <CardTitle>Kilometer goal</CardTitle>
                  <Count style={{ color: "#f58320" }}>
                    <CountUp duration={2.0} end={kilometersToCover} />
                  </Count>
                </DistanceTile>
              </DistancesContainer>
              <BarContainer>
                <StyledProgressBar
                  percent={(kilometersCovered / kilometersToCover) * 100}
                  filledBackground="#007ec9"
                >
                  <Step transition="scale" position={0}>
                    {({ accomplished }) => (
                      <DistanceTile>
                        <StyledRadioButton
                          isSelected
                          style={{ marginTop: "35px" }}
                        >
                          <StyledRadioImg src={AmsterdamSvg} />
                        </StyledRadioButton>
                        <StyledPickLabel>Amsterdam</StyledPickLabel>
                      </DistanceTile>
                    )}
                  </Step>
                  <Step
                    transition="scale"
                    position={100}
                    accomplished={kilometersCovered / kilometersToCover === 1}
                  >
                    {({ accomplished }) => (
                      <DistanceTile style={{ marginTop: "35px" }}>
                        <StyledRadioButton isSelected={accomplished}>
                          <StyledRadioImg src={KathmanduSvg} />
                        </StyledRadioButton>
                        <StyledPickLabel>Kathmandu</StyledPickLabel>
                      </DistanceTile>
                    )}
                  </Step>
                </StyledProgressBar>
              </BarContainer>
            </ProgressContainer>

            <StyledTimeLine className={"vertical-timeline"} animate={isDesktop}>
              <StyledVerticalTimelineElementIcon
                iconStyle={{
                  background: "#007ec9",
                  color: "#007ec9",
                  border: "none",
                }}
                date="Amsterdam"
                icon={<Amsterdam />}
              />
              {tours.data.map((tour, index) => (
                <StyledVerticalTimelineElement
                  key={index}
                  contentStyle={{
                    background: "#fef4ea",
                    color: "#333",
                  }}
                  contentArrowStyle={{
                    borderRight: "7px solid  #fef4ea",
                  }}
                  date={tour.type}
                  iconStyle={{
                    background: "#007ec9",
                    color: "#007ec9",
                    border: "none",
                  }}
                  icon={tour.type === "cycling" ? <Bike /> : <Run />}
                >
                  <TimeTitle>{tour.title}</TimeTitle>
                  <KilometerLine>
                    <Label>By:</Label> <Kilometers>{tour.name}</Kilometers>
                  </KilometerLine>
                  <KilometerLine>
                    <Label>Distance:</Label>{" "}
                    <Kilometers>{tour.kilometers} kilometers</Kilometers>
                  </KilometerLine>
                </StyledVerticalTimelineElement>
              ))}
              {kilometersCovered > kilometersToCover && (
                <StyledVerticalTimelineElementIcon
                  iconStyle={{
                    background: "#007ec9",
                    color: "#007ec9",
                    border: "none",
                  }}
                  date="Kathmandu"
                  icon={<Kathmandu />}
                />
              )}
            </StyledTimeLine>
          </>
        )}
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  max-width: 800px;
  width: 800px;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 40px;
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 100%;
    padding: 10px;
  }
`;

const StyledTextInput = styled(TextInput)`
  width: 50%;
  margin-top: 33px;
`;

const LongTextInput = styled(TextInput)`
  width: 100%;
  margin-top: 33px;
`;

const ProgressContainer = styled.div`
  width: 100%;
  color: #333333;
  background-color: #fef4ea;
  border-radius: 3px;
  padding: 33px;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 1px 0 rgba(109, 109, 109, 0.5);
`;

const HeaderWrapper = styled.div`
  display: flex;
  width: 1100px;
  margin: auto;
  align-items: center;
  justify-content: center;
  background-size: cover;
  object-fit: contain;
  height: 600px;
  background-repeat: no-repeat;
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 100%;
    object-fit: cover;
    height: 226px;
    background-size: cover;
    background-repeat: no-repeat;
  }
`;

const CardTitle = styled.h4`
  margin-bottom: 10px;
  margin-top: 0px;
  font-family: "Nunito Sans";
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: left;
  color: #333333;
  display: inline-block;
`;

const BarContainer = styled.div`
  width: 90%;
  margin-top: 10px;
  margin: auto;
  margin-bottom: 30px;
`;

const SubTitle = styled.h5`
  margin-bottom: 0px;
  margin-top: 0px;
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: left;
  color: #333333;
  margin-top: 33px;

  display: inline-block;
`;

const Label = styled.div`
  margin-bottom: 0px;
  margin-top: 0px;
  font-family: "Nunito Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: left;
  color: #333333;
  opacity: 0.7;
  margin-top: 3px;
`;

const TimeTitle = styled.h5`
  margin-bottom: 0px;
  margin-top: 0px;
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: left;
  color: #333333;

  display: inline-block;
`;

const AddTourContainer = styled.div`
  width: 100%;
  color: #333333;
  background-color: #fef4ea;
  border-radius: 3px;
  padding: 33px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 1px 0 rgba(109, 109, 109, 0.5);
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 16px;
  justify-content: space-between;
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    flex-direction: column;
  }
`;

const IconContainer = styled.div`
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 100%;
  }
`;

const StyledRadioContainer = styled(RadioContainer)`
  align-items: flex-start;
  margin-top: 20px;
`;

const KilometerLine = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
`;

const DonateButton = styled(Link)`
  font-family: "Nunito Sans";
  font-style: normal;
  font-size: 16px;
  height: 100%;
  line-height: 35px;
  align-content: center;
  font-weight: 600;
  letter-spacing: 0;
  text-align: center;
  color: #333333;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-bottom: 10px;
  border-radius: 50px;
  margin-top: 20px;

  
    display: flex;
    justify-content: center;
    align-items: center;
    width: 350px;
    height: 65px;
    background-color: #f58320;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    color: white;
    margin-bottom: 30px;
    border-width: 0px;
    position: relative;
    text-decoration: none;

`;

const Kilometers = styled.div`
  margin-bottom: 0px;
  margin-top: 0px;
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  text-align: left;
  margin-left: 5px;
  margin-right: 5px;
  color: #333333;
`;

const LineItem = styled.div`
  height: 100px;
  width: 5px;
  align-items: center;
  border-left: 4px solid #333333;
`;

const ButtonConfirm = styled.button`
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
  width: 40%;
  height: 50px;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: #f58320;
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 36px;
  cursor: pointer;
  color: white;
  margin-top: 10px;
  letter-spacing: 0.2px;
  text-align: center;
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 100%;
  }
`;

const StyledTimeLine = styled(VerticalTimeline)`
  ::before {
    background: #424242;
  }
  .vertical-timeline-element-date {
    color: #333333;
    text-transform: capitalize;
    font-family: "Nunito Sans";
    font-size: 18px;
    opacity: 1;
  }
`;

const StyledVerticalTimelineElement = styled(VerticalTimelineElement)`
  .vertical-timeline-element-content {
    border-radius: 3px;
    box-shadow: 0 1px 1px 0 rgba(109, 109, 109, 0.5);
  }
  .vertical-timeline-element-icon {
    box-shadow: none;
  }
`;

const StyledVerticalTimelineElementIcon = styled(VerticalTimelineElement)`
  .vertical-timeline-element-icon {
    box-shadow: none;
  }
`;

const DistancesContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 33px;
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    flex-direction: column;
  }
`;

const DistanceTile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Count = styled.div`
  font-family: "Nunito Sans";
  font-size: 50px;
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    font-size: 30px;
  }
`;

const StyledProgressBar = styled(ProgressBar)`
  margin-top: 33px;
`;

const StyledRadioButton = styled(RadioButton)`
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 30px;
    height: 30px;
  }
`;

const StyledRadioImg = styled(RadioImg)`
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    width: 20px;
    height: 20px;
  }
`;

const StyledPickLabel = styled(PickLabel)`
  @media only screen and (min-width: 275px) and (max-width: 480px) {
    font-size: 14px;
  }
`;

export default KathmanTourPage;

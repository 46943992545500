import React from "react";
const Bike = () => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 496.628 496.628"
    >
      <g id="XMLID_511_" fill="white">
        <path
          id="XMLID_512_"
          d="M141.871,417.141c-11.752,9.038-26.278,14.631-42.217,14.631c-38.411,0-69.667-31.256-69.667-69.667
		c0-38.419,31.256-69.676,69.667-69.676c28.99,0,53.853,17.825,64.336,43.067l21.816-22.626
		c-17.18-29.986-49.118-50.427-86.152-50.427C44.608,262.443,0,307.061,0,362.105c0,55.036,44.608,99.654,99.654,99.654
		c25.898,0,49.265-10.122,66.991-26.326c-6.803-2.283-13.256-5.905-18.77-11.215C145.6,422.02,143.637,419.619,141.871,417.141z"
        />
        <path
          id="XMLID_513_"
          d="M293.191,263.204l-52.769-30.65c23.533-14.729,14.807-9.274,38.341-24.004l-0.741,7.497
		c-1.27,12.759,8.133,24.435,19.971,26.834c0.283,0.058,92.635,15.755,96.003,15.755c11.997,0,22.589-8.668,24.617-20.899
		c2.265-13.617-6.939-26.484-20.556-28.737l-67.989-11.295l3.233-32.445c3.259-20.733,4.674-27.234-3.067-39.603l-9.732-15.559
		c-12.573-20.08-39.084-26.22-59.212-13.618l-106.515,66.689c-26.161,16.38-38.919,50.917-17.561,76.323
		c8.344,9.928,89.47,59.781,89.47,59.781l-65.703,68.153c-11.499,11.918-11.157,30.905,0.771,42.394
		c5.818,5.611,13.315,8.403,20.811,8.403c7.859,0,15.707-3.064,21.583-9.176l95.564-99.105
		C313.192,295.992,310.03,272.984,293.191,263.204z"
        />
        <path
          id="XMLID_514_"
          d="M405.134,127.072c23.36-14.632,30.437-45.42,15.813-68.769c-14.621-23.369-45.41-30.446-68.767-15.823
		c-23.35,14.623-30.427,45.4-15.813,68.749C350.987,134.599,381.776,141.686,405.134,127.072z"
        />
        <path
          id="XMLID_515_"
          d="M396.973,262.443c-55.619,0-99.654,45.888-99.654,99.662c0,55.036,44.61,99.654,99.654,99.654
		c55.034,0,99.655-44.618,99.655-99.654C496.628,307.061,452.007,262.443,396.973,262.443z M396.973,431.772
		c-38.411,0-69.667-31.256-69.667-69.667c0-38.419,31.257-69.676,69.667-69.676c38.412,0,69.668,31.257,69.668,69.676
		C466.642,400.516,435.385,431.772,396.973,431.772z"
        />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
};

export default Bike;
